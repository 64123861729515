@tailwind base;
@tailwind components;
@tailwind utilities;

.stripes-pattern {
  background-color: #f8fafc;
  background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23f1f5f9' fill-opacity='1' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E");
}

.tox-editor-header {
  box-shadow: none !important;
  border-bottom: 1px solid #cbd5e1 !important;
}

.tox-tbtn svg {
  fill: #334155 !important;
}

.shadow-none {
  box-shadow: none !important;
}

.PopoverContent {
  animation-duration: 0.6s;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
}
.PopoverContent[data-side='top'] {
  animation-name: slideUp;
}
.PopoverContent[data-side='bottom'] {
  animation-name: slideDown;
}
.PopoverContent[data-side='right'] {
  animation-name: slideRight;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRight {
  from {
    opacity: 0;
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.AccordionChevron {
  transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
}

.AccordionTrigger[data-state='open'] > svg.AccordionChevron {
  transform: rotate(180deg);
}

.AccordionTrigger[data-state='closed'] {
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

/* fixing react-tel css */
.react-tel-input .form-control {
  font-size: 16px !important;
}

@media (min-width: 640px) {
  .react-tel-input .form-control {
    font-size: 14px !important;
  }
}

input[type='color'] {
  -webkit-appearance: none;
  appearance: none;
  border: none;
}
input[type='color']::-webkit-color-swatch-wrapper {
  padding: 0;
}
input[type='color']::-webkit-color-swatch {
  border: none;
}

@layer base {
  :root {
    --color-brand: 37 99 235;
  }
}
