@import '../../../../packages/ui/src/styles/globals.css';

#__next {
  height: 100vh;
}

html,
body {
  width: 100%;
  margin: 0px;
  padding: 0px;
  -webkit-tap-highlight-color: transparent;
}

.pac-container {
  pointer-events: auto;
}
